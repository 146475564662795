<template>
    <!-- site content -->
    <div class="main-page pt-3">
        <main class="main-container">
            <div class="container custom-container">
                <nav aria-label="breadcrumb" class="sm-text">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link :to="'/dashboard'">Dashboard</router-link>
                        </li>
                        <li class="breadcrumb-item" v-if="$route.meta.backURL">
                            <router-link :to="`${$route.meta.backURL}`">{{ $route.meta.bredCrumbs[0] }} </router-link>
                        </li>
                        <li class="breadcrumb-item" aria-current="page" v-if="$route.meta.bredCrumbs[1]">
                            {{ $route.meta.bredCrumbs[1] }}
                        </li>
                        <li class="breadcrumb-item active" aria-current="page" v-else>
                            {{ $route.meta.bredCrumbs[0] }}
                        </li>
                    </ol>
                </nav>
                <section>
                    <div class="row">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex mb-3">
                            <div class="table-box p-xxl-3 p-xl-3 p-lg-3 p-md-2 p-sm-1 p-0">

                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="stepper-wrapper">
                                            <div class="icon-tab col-xs-12 col-sm-2 col-sm-offset-3 stepper-item"
                                                 :class="{'active': steps.current_step == 1, 'completed': steps.current_step >= 1}"
                                                 @click.prevent="goToStep(1)">
                                                <div class="step-counter text-white">
                                                    <font-awesome-icon :icon="['fas', 'file-alt']"></font-awesome-icon>
                                                </div>
                                                <span class="icon-label">Project Info</span>
                                            </div>
                                            <div class="icon-tab col-xs-12 col-sm-2 stepper-item"
                                                 :class="{'disable': steps.max_step < 2,'active': steps.current_step == 2,
                                                 'completed': steps.current_step >= 2}"
                                                 @click.prevent="goToStep(2)">
                                                <div class="step-counter text-white">
                                                    <font-awesome-icon :icon="['fas', 'user']"></font-awesome-icon>
                                                </div>
                                                <span class="icon-label">Identification</span>
                                            </div>
                                            <div class="icon-tab col-xs-12 col-sm-2 stepper-item"
                                                 :class="{'disable': steps.max_step < 3,'active': steps.current_step == 3,
                                                 'completed': steps.current_step >= 3}"
                                                 @click.prevent="goToStep(3)">
                                                <div class="step-counter text-white">
                                                    <font-awesome-icon :icon="['fas', 'users']"></font-awesome-icon>
                                                </div>
                                                <span class="icon-label">Project Roles</span>
                                            </div>
                                            <div class="icon-tab col-xs-12 col-sm-2 stepper-item"
                                                 :class="{'disable': steps.max_step < 4,'active': steps.current_step == 4,
                                                 'completed': steps.current_step >= 4}"
                                                 @click.prevent="goToStep(4)">
                                                <div class="step-counter text-white">
                                                    <font-awesome-icon :icon="['fas', 'file']"></font-awesome-icon>
                                                </div>
                                                <span class="icon-label">Default Document</span>
                                            </div>
                                            <div class="icon-tab col-xs-12 col-sm-2 stepper-item"
                                                 :class="{'disable': steps.max_step < 5,'active': steps.current_step == 5,
                                                 'completed': steps.current_step >= 5}"
                                                 @click.prevent="goToStep(5)">
                                                <div class="step-counter text-white">
                                                    <font-awesome-icon :icon="['fas', 'people-carry']"></font-awesome-icon>
                                                </div>
                                                <span class="icon-label">Project Leader</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Form ref="resetForm" @submit="submitForm()" v-slot="{ errors }" class="container-fluid">
                                    <div class="item col-sm-12 col-sm-offset-1" v-show="steps.current_step == 1">
                                        <div class="panel panel-default">
                                            <div class="form-group row mb-3">
                                                <div class="col-sm-6">
                                                    <label class="form-control-label">Project Name</label>
                                                    <Field type="text" :class="{'border-danger': errors.project_name}" v-model="data.inputFormData.project_name"
                                                           name="project_name" class="form-control" placeholder="Name" :rules="isRequired" @change="isDisabled()"/>
                                                    <span class="text-danger" v-if="errors.project_name">{{ errors.project_name }}</span>
                                                </div>

                                                <div class="col-sm-6">
                                                    <label class="form-control-label">Serial</label>
                                                    <input type="text" class="form-control" :value="serial_name()" placeholder="Serial" disabled>
                                                </div>
                                            </div>

                                            <div class="form-group row mb-3">
                                                <div class="col-sm-6">
                                                    <span class="me-3">
                                                        <input type="radio" id="productType" name="product-Type" v-model="data.inputFormData.type" :value="'new'" @change="isDisabled()">
                                                        <label for="productType" class="ms-2">New Development</label>
                                                    </span>
                                                    <span>
                                                        <input type="radio" id="productType2" name="product-Type" v-model="data.inputFormData.type" :value="'existing'" @change="isDisabled()">
                                                        <label for="productType2" class="ms-2">Product Update</label>
                                                    </span>
                                                    <div class="mt-1" v-if="data.inputFormData.type == 'existing'">
                                                        <Field as="select" class="form-control" name="project_id" :rules="isRequired" @change="isDisabled()"
                                                                :class="{'border-danger': errors.project_id}"  v-model="data.inputFormData.project_id">
                                                            <option :value="''">Select Project</option>
                                                            <option v-for="(list, index) in completedProjects()" :key="index" :value="list.id">{{list.project_name}}</option>
                                                        </Field>
                                                        <span class="text-danger" v-if="errors.project_id">{{ errors.project_id }}</span>
                                                    </div>
                                                </div>

                                                <div class="col-sm-6">
                                                    <label class="form-control-label">Project Start Date</label>

                                                    <div class="form-group mt-1">
                                                        <Field type="date" class="form-control" name="start_date" :rules="isRequired" @change="isDisabled()"
                                                            :class="{'border-danger': errors.start_date}" v-model="data.inputFormData.start_date"/>
                                                        <span class="text-danger" v-if="errors.start_date">{{ errors.start_date }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="item col-sm-12 col-sm-offset-1" v-show="steps.current_step == 2">
                                        <div class="panel panel-default">
                                            <div class="form-group row mb-3">
                                                <div class="col-sm-6 mt-3">
                                                    <label class="form-control-label">Project Goal</label>
                                                    <Field as="textarea" class="form-control" rows="2" name="project_goal"  :rules="isRequired" @change="isDisabled()"
                                                           :class="{'border-danger': errors.project_goal}" v-model="data.inputFormData.project_goal"></Field>
                                                    <span class="text-danger" v-if="errors.project_goal">{{ errors.project_goal }}</span>
                                                </div>

                                                <div class="col-sm-6 mt-3">
                                                    <label class="form-control-label">Project Scope</label>
                                                    <Field as="textarea" class="form-control" rows="2" name="project_scope"  :rules="isRequired" @change="isDisabled()"
                                                           :class="{'border-danger': errors.project_scope}" v-model="data.inputFormData.project_scope"></Field>
                                                    <span class="text-danger" v-if="errors.project_scope">{{ errors.project_scope }}</span>
                                                </div>

                                                <div class="col-sm-6 mt-3">
                                                    <label class="form-control-label">Project Classification / Methodology</label>
                                                    <Field as="textarea" class="form-control" rows="2" name="classification"  :rules="isRequired" @change="isDisabled()"
                                                           :class="{'border-danger': errors.classification}" v-model="data.inputFormData.classification"></Field>
                                                    <span class="text-danger" v-if="errors.classification">{{ errors.classification }}</span>
                                                </div>

                                                <div class="col-sm-6 mt-3">
                                                    <label class="form-control-label">Project Constraints and Dependencies</label>
                                                    <textarea class="form-control" rows="2" v-model="data.inputFormData.constraints_dependencies"></textarea>
                                                </div>

                                                <div class="col-sm-6 mt-3">
                                                    <label class="form-control-label">Project Opportunities and Risks</label>
                                                    <textarea class="form-control" rows="2" v-model="data.inputFormData.opportunities_risks"></textarea>
                                                </div>

                                                <div class="col-sm-6 mt-3">
                                                    <label class="form-control-label">Program Name</label>
                                                    <input type="text" class="form-control" placeholder="Name" v-model="data.inputFormData.identification.program_name">
                                                </div>

                                                <div class="col-sm-6 mt-3">
                                                    <label class="form-control-label">Product Name</label>
                                                    <Field type="text" class="form-control" placeholder="Product Name"
                                                           v-model="data.inputFormData.identification.product_name"
                                                           name="product_name" :rules="isRequired" :class="{'border-danger': errors.product_name}" @change="isDisabled()"/>
                                                    <span class="text-danger" v-if="errors.product_name">{{ errors.product_name }}</span>
                                                </div>

                                                <div class="col-sm-6 mt-3">
                                                    <label class="form-control-label">Product Number</label>
                                                    <input type="text" class="form-control" placeholder="Program Number"
                                                           v-model="data.inputFormData.identification.program_number">
                                                </div>
                                            </div>

                                            <div class="form-group row mb-3">
                                                <label class="form-control-label">Estimated Total Project Cost</label>
                                                <div class="col-sm-6">
                                                    <input type="number" class="form-control" v-model="data.inputFormData.framework.project_cost">
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="item col-sm-12 col-sm-offset-1" v-show="steps.current_step == 3">
                                        <div class="panel panel-default">
                                            <div class="form-group row mb-3">
                                                <div class="form-check col-sm-4" v-for="(list, index) in data.dataList" :key="index">
                                                    <label class="form-check-label">
                                                        {{ list.name }}
                                                        <input class="form-check-input" type="checkbox" :value="list.id" v-model="data.inputFormData.selectedIds">
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="item col-sm-12 col-sm-offset-1" v-show="steps.current_step == 4">
                                        <div class="panel panel-default">
                                            <h3 class="border-bottom pb-3 mb-3">Default Documents</h3>
                                            <div class="form-group row mb-3">
                                                <div class="form-check col-sm-6" v-for="(list, index) in documents()" :key="index">
                                                    <label class="form-check-label text-break">
                                                        {{ list.name }}
                                                        <input class="form-check-input" type="checkbox" :value="list.id" v-model="data.inputFormData.documentIds">
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="item col-sm-12 col-sm-offset-1" v-show="steps.current_step == 5">
                                        <div class="panel panel-default">
                                            <div class="position-relative">
                                                <div class="form-group row">
                                                    <div class="col-sm-6">
                                                        <label class="form-control-label">Project Leader</label>
                                                        <Field type="text" class="form-control" name="project_leader" :class="{'border-danger': errors.project_leader}"
                                                            :rules="isRequired" @change="isDisabled()" @focus="startTypingEvent()" @input="startTypingEvent()"
                                                            v-model="data.inputFormData.project_leader.email"/>
                                                        <span class="text-danger" v-if="errors.project_leader">{{ errors.project_leader }}</span>
                                                    </div>
                                                </div>
                                                <div class="form-group row mb-3 position-absolute w-100" v-if="data.userList.length > 0 && data.search">
                                                    <div class="col-sm-6 pe-0">
                                                        <div class="dropdown-search-result-wrapper custom-scrollbar"
                                                        v-on:scroll="handleScrolledTopToBottom">
                                                            <a v-for="(item, index) in data.userList"
                                                            :key="index"
                                                            class="dropdown-item"
                                                            href="#"
                                                            @click.stop="selectAutocomplete(item)">
                                                                {{ item.email }}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row mb-3" v-if="data.inputFormData.project_leader && !data.inputFormData.project_leader.companyUser">
                                                <div class="col-sm-6">
                                                    <label class="form-control-label">Rationale why you need someone outside approved </label>
                                                    <Field as="textarea" class="form-control" name="searchRational" :class="{'border-danger': errors.searchRational}"
                                                           :rules="isRequired" @change="isDisabled()" v-model="data.inputFormData.project_leader.searchRational"/>
                                                    <span class="text-danger" v-if="errors.searchRational">{{ errors.searchRational }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mt-4">
                                        <div class="text-right">
                                            <button class="btn btn-primary ms-2" type="button"
                                                    v-if="steps.max_step != 5" @click="nextStep()"
                                                    :disabled="nextButtonCheck.isDisabled"
                                            >Next </button>

                                            <button v-if="steps.max_step == 5" type="submit" class="btn btn-primary">
                                                Submit
                                            </button>

                                            <router-link :to="$route.meta.backURL" type="button"
                                                         class="btn btn-secondary ms-2">Cancel </router-link>
                                        </div>
                                    </div>

                                </Form>

                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </main>

        <BottomFooter></BottomFooter>
    </div>
</template>

<script>

    /* eslint-disable */
    import {Field, Form, ErrorMessage} from "vee-validate";
    import HelperFunction from "@/common/helpers";
    import {reactive, onMounted, ref, watch} from 'vue'
    import {useRouter} from "vue-router";
    import {useStore} from "vuex";
    import BottomFooter from "@/views/layouts/auth/common/BottomFooter";

    export default {
        name: "Create Project",
        components: {
            Form,
            Field,
            ErrorMessage, BottomFooter
        },
        setup() {
            const router = useRouter()
            const store  = useStore()
            const { data, storeFormData, getFormFile, getAllData, resetForm, formPermissionResolver, debounceEvent } = HelperFunction()
            const company = store.getters.getSelectedBoard

            /** form permission **/
            formPermissionResolver('projects');
            /** end **/

            data.setURL = vueConfig.Project.ProjectEndPoint
            let steps = reactive({
                current_step: 1,
                max_step: 1,
            })
            let setParams = {
                check: true,
                company_id: company.id == 0 ? null : company.id
            };
            data.inputFormData = {
                category: 0,
                project_id: '',
                type: 'new',
                identification: {},
                framework: {},
                project_leader: {
                    companyUser: true
                },
                company_id: setParams.company_id,
                selectedIds: [],
                documentIds: [],
            }
            // FOR USER SEARCH
            data.userList = []
            let nextButtonCheck = reactive({
                isDisabled: 1
            })
            data.currentPage = 1
            data.lastPage = 1
            
            function isDisabled() {
                if (steps.current_step === 1) {
                    if (data.inputFormData.project_name && data.inputFormData.start_date) {
                        nextButtonCheck.isDisabled = 0
                    }
                    if (data.inputFormData.project_id == '' && data.inputFormData.type == 'existing') {
                        nextButtonCheck.isDisabled = 1
                    }
                }
                if (steps.current_step === 2) {
                    if (data.inputFormData.project_goal && data.inputFormData.project_scope
                        && data.inputFormData.classification && data.inputFormData.identification.product_name) {
                        nextButtonCheck.isDisabled = 0
                    }
                }
            }
            //TAB VALIDATION
            function validate() {
                if (steps.current_step === 1) {
                    if (data.inputFormData.project_name ==('' || undefined) ||
                        data.inputFormData.start_date == ('' || undefined)) {
                        return false
                    }
                }
                if (steps.current_step === 2) {
                    if (data.inputFormData.project_goal == ('' || undefined) ||
                        data.inputFormData.project_scope == ('' || undefined) ||
                        data.inputFormData.classification == ('' || undefined) ||
                        data.inputFormData.identification.product_name == ('' || undefined) ) {
                        return false
                    }
                }
                if (steps.current_step === 5){
                    if (data.inputFormData.project_leader.email == ('' || undefined)) {
                        return false
                    }
                    if (!data.inputFormData.project_leader.companyUser) {
                        if (!data.inputFormData.project_leader.searchRational) {
                            return false
                        }
                    }
                }
                return true
            }
            function nextStep() {
                steps.current_step++
                if (steps.max_step < steps.current_step) {
                    steps.max_step = steps.current_step
                }
                if (steps.max_step === 2) {
                    nextButtonCheck.isDisabled = 1
                }
            }
            function goToStep(value) {
                if (!validate() ) {
                    return
                }
                if (nextButtonCheck.isDisabled) {
                    return
                }
                steps.current_step = value
            }
            async function submitForm() {
                if (!validate()) {
                    return
                }
                await storeFormData({url:data.setURL}, data.inputFormData)
                    .then(() => {
                        const company = store.getters.getSelectedBoard
                        const company_id = {
                            company_id: company.id ? company.id : null
                        };
                        store.dispatch('getDashboardData', company_id)
                        router.push('/projects')
                    })
            }
            // CALL GETTERS
            onMounted(() => {
                getAllData(vueConfig.Project.ProjectsLeaderEndPoint, setParams)
            })
            function serial_name() {
                let count = store.getters.getDashBoardData.project_opened + 1;
                let serial_name = data.inputFormData.project_name ? data.inputFormData.project_name : ''
                let space = "#"
                data.inputFormData.serial = serial_name + space + count
                return data.inputFormData.serial
            }
            function documents() {
                return store.getters.getDocuments
            }
            function completedProjects() {
                return store.getters.getCompletedProjects
            }

            // FOR GET TEAM LEAD FUNCTIONS
            function getTeamLeader(scroll = false) {
                const email = data.inputFormData.project_leader.email ? data.inputFormData.project_leader.email : ' '
                const emailLength = email ? email.length : 0
                data.currentPage = scroll ? data.currentPage : null
                const url = `${vueConfig.User.UsersFilterWithPaginationEndPoint}=${email}&company_id=${setParams.company_id}&max_count=10&page=${data.currentPage}`
                data.search = true;

                // if (emailLength >= 2) {
                    store.dispatch('getUsersByEmail', {
                        'action': url
                    }).then(response => {
                        data.lastPage = response.entries.last_page
                        if (response.paginate && scroll) 
                            data.userList.unshift( ...response.entries.data)
                        else if (response.paginate)
                            data.userList = response.entries.data
                        else if (!response.paginate)
                            data.userList = response.entries

                    }).catch(error => {
                        console.log(error);
                    })
                // } else {
                //     data.userList = []
                // }
            }
            
            // DEBOUNCE REQUEST FOR 500ms
            const startTypingEvent = debounceEvent(() => getTeamLeader())

            const selectAutocomplete = (obj) => {
                data.inputFormData.project_leader = obj;
                data.search = false;
            }
            function handleScrolledTopToBottom(e) {
                // SCROLL BOTTOM CHECK AND EXECUTE FUNCTION
                if (e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight && data.currentPage < data.lastPage) {
                    data.currentPage++;
                    getTeamLeader(true);
                }
            }

            //VALIDATION
            function isRequired(value) {
                if (value) {
                    return true;
                }
                return "This field is required";
            }
            return {
                data, steps, nextStep, submitForm,
                goToStep, isRequired, storeFormData, getFormFile,
                documents, nextButtonCheck, isDisabled,
                serial_name, completedProjects, getTeamLeader,
                selectAutocomplete, resetForm, handleScrolledTopToBottom, startTypingEvent
            };
        },
    }
    /* eslint-disable */

</script>

<style src="@/assets/css/multiple-tab.css"/>

<style scoped>

    .dropdown-search-result-wrapper {
        padding: 0.8rem 0;
        max-height: 150px;
        overflow-y: auto;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
        z-index: 100;
        background: #fff;
        left: 0;
    }

    .dropdown-search-result-wrapper .dropdown-item {
        font-size: 95% !important;
        color: #898989;
        padding: 0.5rem 1rem !important;
    }

    .dropdown-search-result-wrapper .dropdown-item.active {
        color: #ffffff !important;
        background-color: #5c76fa !important;
    }

    .dropdown-search-result-wrapper .dropdown-item.selected {
        color: #ffffff !important;
        background-color: #5c76fa !important;
    }

    .dropdown-search-result-wrapper .dropdown-item.selected .check-sign {
        display: block;
    }

    .dropdown-search-result-wrapper .dropdown-item:hover {
        background-color: #5c76fa !important;
        color: #ffffff !important;
    }

    .custom-scrollbar::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    .custom-scrollbar::-webkit-scrollbar-track {
        background: transparent;
    }

    .custom-scrollbar::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 5px;
    }

    .custom-scrollbar:hover::-webkit-scrollbar-thumb {
        background: #5c76fa;
    }

    .custom-scrollbar:hover::-webkit-scrollbar-thumb:hover {
        background-color: #a3b4f9;
    }

</style>
